import serverCall from "../serverCall";

const getAllTransactions = (page, size, search) => {
  try {
    const response = serverCall.post(
      `/help-request/admin/get-all-transaction?page=${page}&size=${size}&search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const TransactionsService = {
  getAllTransactions,
};

export default TransactionsService;
