import serverCall from "../serverCall";

const getAllVendor = (page, limit, search) => {
  try {
    const response = serverCall.get(
      `/vendor/all?page=${page}&limit=${limit}&search=${search}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const getUserDetails = (userId) => {
  const response = serverCall.get(`/users/get-profile?userId=${userId}`);
  return response;
};

const updateUserDetails = (userId, body) => {
  const response = serverCall.patch(
    `/users/update-user?userId=${userId}`,
    body
  );
  return response;
};

const deleteUser = (userId) => {
  try {
    const response = serverCall.post(
      `/users/admin/deleteUser?userId=${userId}`
    );
    return response;
  } catch (error) {
    console.log("🚀  deleteUser  error:", error);
  }
};

/* const addDocuments = (body, userId) => {
  const response = serverCall.patch(
    `/users/income-document-admin?userId=${userId}`,
    body
  );
  return response;
}; */

const updateDocumentStatus = (userId, status) => {
  const response = serverCall.post(
    `/users/admin/update-document-status?userId=${userId}&status=${status}`
  );
  return response;
};

const updatePayyroStatus = (userId, status) => {
  const response = serverCall.post(
    `/users/admin/update-payyro-status?userId=${userId}&status=${status}`
  );
  return response;
};

const updateCategory = (body) => {
  const response = serverCall.patch(`/category/update-category`, body);
  return response;
};

const addCategory = (name) => {
  const response = serverCall.post(`/category/add-category?name=${name}`);
  return response;
};

const deleteCategory = (categoryId) => {
  const response = serverCall.delete(`/category/delete-category/${categoryId}`);
  return response;
};

const deleteVideo = (body) => {
  try {
    const response = serverCall.post(
      `/users/getHelp/delete-profile-vide`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const addVideo = (body, userId) => {
  try {
    const response = serverCall.patch(
      `/users/edit-video?userId=${userId}`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const addProfileImages = (data) => {
  try {
    const response = serverCall.patch(`/users/admin/profile-images`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const deleteProfileImages = (body) => {
  try {
    const response = serverCall.post(
      `/users/getHelp/delete-profile-image`,
      body
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const addVendor = (body) => {
  try {
    const response = serverCall.post(`/vendor/register`, body);
    return response;
  } catch (error) {
    throw error;
  }
};
const editVendor = (body) => {
  try {
    const response = serverCall.patch(`/vendor/update/admin`, body);
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteVendor = (id) => {
  try {
    const response = serverCall.delete(`/vendor/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};
const getVendorDetails = (id) => {
  try {
    const response = serverCall.get(`/vendor/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const VendorService = {
  getAllVendor,
  getUserDetails,
  updateUserDetails,
  updateDocumentStatus,
  updatePayyroStatus,
  addCategory,
  updateCategory,
  deleteCategory,
  //   addDocuments,
  deleteUser,
  deleteVideo,
  addVideo,
  addProfileImages,
  deleteProfileImages,
  addVendor,
  getVendorDetails,
  editVendor,
  deleteVendor,
};

export default VendorService;
