import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import VendorService from "../service/vendor.service";
import Loader from "../assets/images/loader.gif";
import { LuUpload } from "react-icons/lu";
import { IoCloseOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffSharp } from "react-icons/io5";
import Swal from "sweetalert2";
// import DocumentLoader from "../assets/images/";
import {
  AiFillDelete,
  AiFillEdit,
  AiFillFileExcel,
  AiFillMail,
} from "react-icons/ai";
import { BsFileEarmarkPdfFill, BsFileEarmarkWordFill } from "react-icons/bs";
import { MdOutlineAdd } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import { BiSolidFileJpg, BiSolidFilePng } from "react-icons/bi";

const showMessage = (msg, type) => {
  const toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 3000,
    customClass: { container: "toast" },
  });
  toast.fire({
    icon: type,
    title: msg,
    padding: "10px 20px",
  });
};

const AddVendor = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const passwrodToggle = () => {
    setPasswordVisible((prev) => !prev);
  };

  const confirmPasswrodToggle = () => {
    setConfirmPasswordVisible((prev) => !prev);
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      company: "",
      address: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      company: Yup.string().required("Company name is required"),
      address: Yup.string().required("Address is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      /* password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .required("Password is required"), */
      password: Yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters long")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async ({ name, company, email, address, password }) => {
      const body = {
        company,
        address,
        name,
        email,
        password,
        isCreatedByUser: false,
      };
      try {
        setIsLoading(true);
        const response = await VendorService?.addVendor(body);
        if (response) {
          navigate("/vendor");
          showMessage(
            /* response?.data?.message || */ "Vendor created successfully",
            "success"
          );
        }
      } catch (error) {
        showMessage(
          error?.response?.data?.message ||
            error?.message ||
            error ||
            "Something went wrong",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  console.log(formik.errors);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-[70%_30%] gap-4">
        <div className="">
          <div className="panel overflow-hidden  max-w-[500px] w-full">
            <div className="tab:px-26 px-4 py-4 tab:py-5 w-full">
              <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-between items-center flex-col pb-6 gap-4 w-full">
                  <h5 className="tab:text-xl text-base font-semibold text-start pb-2 w-full">
                    Add Vendor Details
                  </h5>
                  <div className="w-full pb-4">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Enter Name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="text-red-600">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="w-full pb-4">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-input"
                      // readOnly={true}
                      placeholder="Enter Email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-red-600">{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="w-full pb-4">
                    <label>Company Name</label>
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Enter Company Name"
                      onBlur={formik.handleBlur}
                      name="company"
                      onChange={formik.handleChange}
                      value={formik.values.company}
                    />
                    {formik.touched.company && formik.errors.company && (
                      <div className="text-red-600">
                        {formik.errors.company}
                      </div>
                    )}
                  </div>
                  <div className="w-full pb-4">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-input"
                      placeholder="Enter Address"
                      onBlur={formik.handleBlur}
                      name="address"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <div className="text-red-600">
                        {formik.errors.address}
                      </div>
                    )}
                  </div>

                  <div className="w-full pb-4">
                    <label>Password</label>
                    <div className="relative">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-input w-full h-10 pr-10" // Ensures consistent height
                        placeholder="Enter Password"
                        onBlur={formik.handleBlur}
                        name="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <button
                        type="button"
                        onClick={passwrodToggle}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                      >
                        {passwordVisible ? (
                          <IoEyeOffSharp size={20} />
                        ) : (
                          <IoEyeOutline size={20} />
                        )}
                      </button>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                      <div className="text-red-600 mt-1">
                        {formik.errors.password}
                      </div> // Adds spacing below input
                    )}
                  </div>

                  <div className="w-full pb-4">
                    <label>Confirm Password</label>
                    <div className="relative">
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-input w-full h-10 pr-10"
                        placeholder="Enter Confirm Password"
                        onBlur={formik.handleBlur}
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                      />
                      <button
                        type="button"
                        onClick={confirmPasswrodToggle}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                      >
                        {confirmPasswordVisible ? (
                          <IoEyeOffSharp size={20} />
                        ) : (
                          <IoEyeOutline size={20} />
                        )}
                      </button>
                    </div>
                    {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword && (
                        <div className="text-red-600 mt-1">
                          {formik.errors.confirmPassword}
                        </div>
                      )}
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    onClick={() => navigate("/vendor")}
                    className="btn bg-white text-danger font-bold border border-danger hover:bg-danger hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type={isLoading ? "button" : "submit"}
                    className={`max-h-[38px] btn bg-primary text-white font-bold border border-primary  ${
                      isLoading ? "hover:bg-primary" : "hover:bg-transparent"
                    } hover:text-primary duration-200 text-sm w-full`}
                  >
                    {isLoading ? (
                      <div className="h-full flex justify-center items-center">
                        <span className="loader"></span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVendor;
