import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "mantine-datatable";
import { setPageTitle } from "../store/themeConfigSlice";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import CustomPagination from "../components/Layouts/CustomPagination";
import { useNavigate } from "react-router-dom";
import IconSearch from "../components/Icon/IconSearch";
import IconXCircle from "../components/Icon/IconXCircle";
import DropdownCustom from "../components/DropdownCustom";
import VendorService from "../service/vendor.service";
import Swal from "sweetalert2";
import { MdEdit } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { useDebounce } from "use-debounce";

/* const status = [
  { label: "All", value: "" },
  { label: "Verified", value: "Verified" },
  { label: "Unverified", value: "Approve" },
]; */

const VendorTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZES = [10, 20, 30, 50, 100];
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [value] = useDebounce(searchText, 1000);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
  }, [page, pageSize]);

  const handleChange = (type, page) => {
    const total = totalCount / pageSize;
    const newPage = Math.ceil(total);
    if (type === "Next") {
      if (page !== newPage) {
        setPage(page + 1);
      }
    } else if (type === "Previous") {
      if (page !== 1) {
        setPage(page - 1);
      }
    } else if (type === "First") {
      if (page !== 1) {
        setPage(1);
      }
    } else if (type === "Last") {
      if (page !== newPage) {
        setPage(newPage);
      }
    } else {
      setPage(page);
    }
  };

  const handleDataPerPage = (e) => {
    setPageSize(e.target.value);
  };

  /* const handleNavigate = (id) => {
    navigate(`/user/details/${id}`);
  }; */

  const handleEdit = (id) => {
    navigate(`/vendor/edit/${id}`);
  };

  const getAllVendor = async () => {
    try {
      setIsLoading(true);
      const response = await VendorService.getAllVendor(page, pageSize, value);
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      showMessage(
        error?.response?.data?.message ||
          error?.response?.message ||
          error?.message ||
          "Somthing went wrong",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await VendorService?.deleteVendor(userId);
      if (response) {
        showMessage("Vendor deleted successfully", "success");
        getAllVendor();
      }
    } catch (error) {
      showMessage(
        error?.response?.data?.message ||
          error.message ||
          "Something went wrong",
        "error"
      );
    } finally {
      setIsDelete(false);
      setUserId("");
    }
  };

  useEffect(() => {
    getAllVendor();
  }, [page, pageSize, value]);

  const showMessage = (msg, type) => {
    const toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      customClass: { container: "toast" },
    });
    toast.fire({
      icon: type,
      title: msg,
      padding: "10px 20px",
    });
  };

  return (
    <div>
      <div className="panel">
        <div className="mb-4 flex justify-between items-center gap-4">
          <div className="relative max-w-xs w-full">
            <input
              type="text"
              className="form-input pe-9 peer sm:bg-transparent bg-gray-100 placeholder:tracking-widest"
              placeholder="Search..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button
              type="button"
              className="absolute w-9 h-9 right-0 appearance-none peer-focus:text-primary"
            >
              <IconSearch className="mx-auto" />
            </button>
            <button
              type="button"
              className="hover:opacity-80 sm:hidden block absolute top-1/2 -translate-y-1/2 ltr:right-2 rtl:left-2"
              // onClick={() => setSearch(false)}
            >
              <IconXCircle />
            </button>
          </div>
          {/* <DropdownCustom
            data={status}
            width={"100%"}
            name="Select Status"
            setData={setSelectedStatus}
          /> */}
          <div>
            <div
              onClick={() => navigate("/add-vendor")}
              className="cursor-pointer flex justify-center items-center gap-1 btn bg-primary text-white font-bold border border-primary hover:bg-transparent hover:text-primary duration-200 text-sm "
            >
              <FiPlus />
              <span> Add Vendor</span>
            </div>
          </div>
        </div>
        <div className="datatables user-table">
          <DataTable
            noRecordsText="No results match your search query"
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={data}
            columns={[
              {
                accessor: "id",
                title: "ID",
                // sortable: true,
                render: ({ id }, index) => (
                  <div>
                    <strong>{index + 1}</strong>
                  </div>
                ),
              },
              // { accessor: "name", title: "Name" },
              { accessor: "name", title: "Name" },
              { accessor: "company", title: "Company Name" },
              // { accessor: "address", title: "Address" },
              {
                accessor: "address",
                render: ({ address }) => (
                  <span
                    title={address}
                    className="block w-44 max-w-44 truncate overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    {address}
                  </span>
                ),
              },
              {
                accessor: "email",
                // sortable: true,
                render: ({ email }) => <span>{email}</span>,
              },
              // { accessor: "accountNo", title: "Acount No." },
              // { accessor: "bank", title: "Bank" },
              {
                accessor: "action",
                title: "Status",
                render: ({ isAdminVerified }) => (
                  <div>
                    <span
                      className={`${
                        isAdminVerified === true
                          ? "text-success bg-success/15"
                          : "text-danger bg-danger/15 cursor-pointer"
                      } rounded-full px-5 py-1.5 text-xs w-fit font-bold`}
                    >
                      {isAdminVerified ? "Verfired" : "Unverified"}
                    </span>
                  </div>
                ),
              },
              // {
              //   accessor: "action",
              //   title: "Status Of Document",
              //   render: ({ statusOfIncomeDocument }) => (
              //     <div>
              //       <span
              //         className={`${
              //           statusOfIncomeDocument === "Approve"
              //             ? "text-success bg-success/15"
              //             : statusOfIncomeDocument === "Pending"
              //             ? "text-warning bg-warning/15"
              //             : statusOfIncomeDocument === "Rejected"
              //             ? "text-danger bg-danger/15 cursor-pointer"
              //             : ""
              //         } rounded-full px-5 py-1.5 text-xs w-fit font-bold`}
              //       >
              //         {statusOfIncomeDocument}
              //       </span>
              //     </div>
              //   ),
              // },
              {
                accessor: "action",
                title: "Action",
                titleClassName: "!text-center",
                render: ({ _id }) => (
                  <div className="flex items-center justify-center mx-auto gap-2">
                    <div
                      onClick={() => handleEdit(_id)}
                      className="cursor-pointer"
                    >
                      <MdEdit className="h-5 w-5 text-primary/80" />
                    </div>
                    {/* <div
                      onClick={() => handleNavigate(_id)}
                      className="cursor-pointer"
                    >
                      <AiFillEye className="h-6 w-6 text-primary/80" />
                    </div> */}
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setIsDelete(true);
                        setUserId(_id);
                      }}
                    >
                      <AiFillDelete className="h-5 w-5 text-red-600" />
                    </div>
                  </div>
                ),
              },
            ]}
            totalRecords={totalCount}
            recordsPerPage={pageSize}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            loading={isLoading}
            fetching={isLoading}
          />
          <CustomPagination
            total={totalCount}
            page={page}
            handleChange={handleChange}
            dataPerPage={pageSize}
            handleDataPerPage={handleDataPerPage}
            PAGE_SIZES={PAGE_SIZES}
          />
        </div>
      </div>
      {isDelete && (
        <div className="fixed inset-0 z-[999] bg-black/50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="panel overflow-hidden max-w-[500px] w-full">
              <div className="tab:px-26 px-4 py-4 tab:py-5">
                <div className="flex justify-between items-center flex-col pb-6 gap-7">
                  <div className="border-2 border-danger p-2.5 rounded-full">
                    <IoCloseOutline className="text-danger text-4xl" />
                  </div>
                  <h5 className="tab:text-xl text-base font-semibold">
                    Delete Conformation
                  </h5>
                  <p>Are you sure you want to delete this vendor?</p>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    onClick={() => setIsDelete(false)}
                    className="btn bg-white text-primary font-bold border border-primary hover:bg-primary hover:text-white duration-200 text-sm w-full"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="btn bg-danger text-white font-bold border border-danger hover:bg-transparent hover:text-danger duration-200 text-sm w-full"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorTable;
