// import { lazy } from "react";
// import UserTable from "../pages/UserTable.js";
// import UserDetails from "../pages/UserDetails.js";
// import Faq from "../pages/faq.js";
// import HelpRequests from "../pages/HelpRequests/HelpRequests.js";
// import Transactions from "../pages/Transactions/Transactions.js";
// import UserEdit from "../components/UserEdit.jsx";
// import DetailHelpRequests from "../pages/Components/DetailHelpRequests.jsx";
// import { Navigate } from "react-router-dom";
// const Category = lazy(() => import("../pages/Category.js"));
// const Error = lazy(() => import("../components/Error"));
// const LoginBoxed = lazy(() => import("../pages/Authentication/LoginBoxed.js"));

// const routes = [
//   {
//     path: "/",
//     element: <Navigate to="/login" replace />,
//     layout: "blank",
//   },
//   {
//     path: "/category",
//     element: <Category />,
//   },
//   {
//     path: "/user",
//     element: <UserTable />,
//   },
//   {
//     path: "/user/details/:id",
//     element: <UserDetails />,
//   },
//   {
//     path: "/user/edit/:id",
//     element: <UserEdit />,
//   },
//   {
//     path: "/Faq",
//     element: <Faq />,
//   },
//   {
//     path: "/helprequests",
//     element: <HelpRequests />,
//   },
//   {
//     path: "/helprequests/:id",
//     element: <DetailHelpRequests />,
//   },
//   {
//     path: "/transactions",
//     element: <Transactions />,
//   },
//   //Authentication
//   {
//     path: "/login",
//     element: <LoginBoxed />,
//     layout: "blank",
//   },
//   {
//     path: "*",
//     element: <Error />,
//     layout: "blank",
//   },
// ];

// export { routes };

// routes.js
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import UserTable from "../pages/UserTable.js";
import UserDetails from "../pages/UserDetails.js";
import Faq from "../pages/faq.js";
import HelpRequests from "../pages/HelpRequests/HelpRequests.js";
import Transactions from "../pages/Transactions/Transactions.js";
import UserEdit from "../components/UserEdit.jsx";
import DetailHelpRequests from "../pages/Components/DetailHelpRequests.jsx";
import VendorTable from "../pages/VendorTable.js";
import VendorEdit from "../components/VendorEdit.jsx";
import AddVendor from "../components/AddVendor.jsx";
const Category = lazy(() => import("../pages/Category.js"));
const Error = lazy(() => import("../components/Error"));
const LoginBoxed = lazy(() => import("../pages/Authentication/LoginBoxed.js"));

const ProtectedRoute = ({ children }) => {
  const userData = localStorage.getItem("payyro-admin-store");
  const user = JSON.parse(userData);

  if (!user?.token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const userData = localStorage.getItem("payyro-admin-store");
  const user = JSON.parse(userData);

  if (user?.token) {
    return <Navigate to="/user" replace />; // or whatever your default authenticated route is
  }

  return children;
};

const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Navigate to="/user" replace />
      </ProtectedRoute>
    ),
    layout: "blank",
  },
  {
    path: "/category",
    element: (
      <ProtectedRoute>
        <Category />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user",
    element: (
      <ProtectedRoute>
        <UserTable />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vendor",
    element: (
      <ProtectedRoute>
        <VendorTable />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user/details/:id",
    element: (
      <ProtectedRoute>
        <UserDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/user/edit/:id",
    element: (
      <ProtectedRoute>
        <UserEdit />
      </ProtectedRoute>
    ),
  },
  {
    path: "/vendor/edit/:id",
    element: (
      <ProtectedRoute>
        <VendorEdit />
      </ProtectedRoute>
    ),
  },
  {
    path: "/add-vendor",
    element: (
      <ProtectedRoute>
        <AddVendor />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Faq",
    element: (
      <ProtectedRoute>
        <Faq />
      </ProtectedRoute>
    ),
  },
  {
    path: "/helprequests",
    element: (
      <ProtectedRoute>
        <HelpRequests />
      </ProtectedRoute>
    ),
  },
  {
    path: "/helprequests/:id/:type",
    element: (
      <ProtectedRoute>
        <DetailHelpRequests />
      </ProtectedRoute>
    ),
  },
  {
    path: "/transactions",
    element: (
      <ProtectedRoute>
        <Transactions />
      </ProtectedRoute>
    ),
  },
  //Authentication
  {
    path: "/login",
    element: (
      <PublicRoute>
        <LoginBoxed />
      </PublicRoute>
    ),
    layout: "blank",
  },
  {
    path: "*",
    element: <Error />,
    layout: "blank",
  },
];

export { routes };
