import React, { useEffect, useRef } from "react";

const ProgressBar = ({ user }) => {
  // Progreshbar
  const barRef = useRef(null);

  useEffect(() => {
    const bar = barRef.current;
    if (bar) {
      bar.classList.remove("w-0");
      bar.classList.add("w-1/2");
    }
  }, []);

  const calculateProgress = (funded, requested) => {
    const total = parseFloat(funded) + parseFloat(requested);

    return ((funded ? parseFloat(funded) : 0) / parseFloat(requested)) * 100;
    // return (parseFloat(funded) / total) * 100;
  };

  const progressPercentage = calculateProgress(
    user?.fundingAmount,
    user?.requestedAmount
  );

  return (
    <div className="h-1 relative max-w-xl rounded-full overflow-hidden">
      <div className="w-full h-full bg-primary/20 absolute"></div>
      <div
        ref={barRef}
        id="bar"
        style={{ width: `${progressPercentage}%` }}
        className="transition-all ease-out duration-1000 h-full bg-success relative w-0 rounded-full"
      ></div>
    </div>
  );
};

export default ProgressBar;
