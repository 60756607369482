import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";

const DropdownCustom = ({
  data,
  name,
  width,
  type,
  setData,
  defaultData,
  updatePayyroStatus,
  updateDocumentStatus,
}) => {
  const menuRef = useRef();
  const menuListRef = useRef();
  const [showMe, setShowMe] = useState(false);
  const [searchText, setSearchText] = useState(defaultData);
  const [newData, setNewData] = useState(data);

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuListRef.current &&
      !menuListRef.current.contains(event.target)
    ) {
      setShowMe(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleCharter = () => {
    setShowMe(!showMe);
  };

  const searchData = async (text) => {
    setSearchText(text);
    setShowMe(true);
    if (text) {
      try {
        const newData = data.filter((item) => {
          if (item.value.includes(text)) {
            return item.value.includes(text);
          }
        });
        setNewData(newData);
      } catch (error) {}
    } else {
      setNewData(data);
    }
  };

  const handleClick = (item) => {
    setSearchText(item.label);
    setShowMe(false);
    setData(item.value);

    if (type === "document") {
      updateDocumentStatus(item.value);
    } else if (type === "payyro") {
      updatePayyroStatus(item.value);
    }
  };

  console.log("searchText", searchText);

  return (
    <div className="relative w-48">
      <div
        className={`relative flex items-center`}
        onClick={toggleCharter}
        style={{ width: width }}
        ref={menuRef}
      >
        <input
          type="text"
          onChange={(e) => searchData(e.target.value)}
          className={`form-input cursor-pointer font-bold ${
            searchText === "Approve"
              ? "bg-success/15 text-success"
              : searchText === "Pending"
              ? "bg-warning/15 text-warning hover:bg-warning/30"
              : searchText === "Rejected"
              ? "bg-danger/15 text-danger hover:bg-danger/30"
              : ""
          }`}
          placeholder={name}
          value={searchText}
          readOnly
        />
        <div className="absolute h-9 w-9 right-0.5 cursor-pointer border border-transparent rounded-md bg-transparent flex items-center justify-center">
          <IoMdArrowDropdown
            className={`duration-300 ${
              showMe ? "rotate-180 text-primary" : "rotate-0"
            } h-5 w-5 text-xl cursor-pointer text-[#80929f]`}
          />
        </div>
      </div>

      {(searchText || showMe) && (
        <>
          <div
            ref={menuListRef}
            className={`absolute top-[50px] left-0 rounded-lg shadow-full z-50 h-fit w-full overflow-auto bg-white ${
              showMe ? "block" : "hidden"
            }`}
            style={{
              width: width,
            }}
          >
            {newData.map((item, index) => {
              return (
                <div className={`hover:bg-primary/5`} key={index}>
                  <div
                    className={`flex cursor-pointer px-1 py-3 items-center mx-2`}
                    onClick={() => handleClick(item)}
                  >
                    <div className="text-[14px]">{item.label}</div>
                  </div>
                  {newData.length - 1 !== index && (
                    <div className="border-b border-[#ebebeb] mx-2"></div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownCustom;
