import React, { useRef, useEffect, useState } from "react";

const TruncatedText = ({ answer, index, showIndex, showClick }) => {
  const textRef = useRef(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      const element = textRef.current;
      if (element) {
        const lineHeight = parseInt(
          window.getComputedStyle(element).lineHeight
        );
        const elementHeight = element.scrollHeight;
        // Check if content exceeds 2 lines
        setIsTextTruncated(elementHeight > lineHeight * 2);
      }
    };

    // Initial check
    checkTruncation();

    // Check on window resize
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [answer]);

  return (
    <div>
      <div
        ref={textRef}
        className={`text-ellipsis max-w-5xl min-w-xl overflow-hidden text-wrap ${
          showIndex.includes(index) ? "" : "line-clamp-2"
        }`}
        title={answer}
      >
        {answer}
      </div>
      {isTextTruncated && (
        <div className="flex justify-end mr-4">
          {showIndex.includes(index) ? (
            <p
              className="text-xs cursor-pointer hover:underline"
              onClick={() => showClick(index, "less")}
            >
              Show Less
            </p>
          ) : (
            <p
              className="text-xs cursor-pointer hover:underline"
              onClick={() => showClick(index, "more")}
            >
              Show More
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default TruncatedText;
