import { get } from "lodash";
import serverCall from "../serverCall";

const login = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  return serverCall
    .post(`/auth/admin/login?email=${email}&password=${password}`)
    .then((response) => {
      const data = get(response, "data", null);
      if (data) {
        const user = data.data.email;
        const token = data.token;

        console.log("data", data);

        const userAndToken = {
          user,
          token,
        };
        localStorage.setItem(
          "payyro-admin-store",
          JSON.stringify(userAndToken)
        );
      }
      return data;
    });
};

const AuthenticationService = {
  login,
};

export default AuthenticationService;
